import React, {useEffect} from "react";
import {
    IconBrandInstagram,
    IconBrandTiktok,
    IconBrandTwitch,
    IconBrandX,
    IconBrandYoutube,
    IconUserFilled
} from "@tabler/icons-react";

export default function Player({player, index, inView}: { player: PlayerProps, index: number, inView: boolean }) {
    const [imageState, setImageState] = React.useState('loading' as 'loading' | 'loaded' | 'failed');

    const slideAnimation = (inView: boolean) => {
        return "transition-slide ease-in-out duration-500 "
            + (inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[2rem]");
    };

    return (
        <div className={"flex flex-col px-3 text-center text-light items-center gap-2 " + slideAnimation(inView)}
            style={{transitionDelay: (inView ? (index * 200) : 0) + "ms"}}>
            <div>
                {(imageState === 'loading' || imageState === 'failed') && <IconUserFilled
                    className="border-4 border-secondary rounded-full p-2"
                    size={125} color={"#9b9b9b"}/>}
                {(player.iconName && imageState !== 'failed') &&
                    <img src={player.iconName} alt={player.name} className="aspect-square rounded-full"
                         hidden={imageState !== 'loaded'}
                         height={125} width={125}
                         onLoad={() => setImageState('loaded')}
                         onError={() => setImageState('failed')}/>}
            </div>
            <p className="font-bold text-2xl">{player.name}</p>
            <div className="flex justify-center gap-2">
                {player.twitchName && <a href={`https://twitch.tv/${player.twitchName}`} target="_blank" rel="noreferrer">
                    <IconBrandTwitch size={32} className="text-secondary hover:text-[#9146ff]
                    transition-colors duration-200 ease-in-out"/></a>}
                {player.youTubeName && <a href={`https://youtube.com/${player.youTubeName}`} target="_blank" rel="noreferrer">
                    <IconBrandYoutube size={32} className="text-secondary hover:text-[#FF0000]
                    transition-colors duration-200 ease-in-out"/></a>}
                {player.xName && <a href={`https://twitter.com/${player.xName}`} target="_blank" rel="noreferrer">
                    <IconBrandX size={32} className="text-secondary hover:text-[#000000]
                    transition-colors duration-200 ease-in-out"/></a>}
                {player.tiktokName && <a href={`https://tiktok.com/@${player.tiktokName}`} target="_blank" rel="noreferrer">
                    <IconBrandTiktok size={32} className="text-secondary hover:text-[#FE2C55]
                    transition-colors duration-200 ease-in-out"/></a>}
                {player.instagramName && <a href={`https://instagram.com/${player.instagramName}`} target="_blank" rel="noreferrer">
                    <IconBrandInstagram size={32} className="text-secondary hover:text-[#C13584]
                    transition-colors duration-200 ease-in-out"/></a>}
            </div>
        </div>
    );
}

export interface PlayerProps {
    name: string,
    iconName?: string,
    twitchName?: string,
    youTubeName?: string,
    xName?: string,
    instagramName?: string,
    tiktokName?: string
}