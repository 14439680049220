import {useEffect, useState} from "react";

export default function Scrollbar() {
    const [scroll, setScroll] = useState(-1);
    const [barHeight, setBarHeight] = useState(0);

    useEffect(() => {

        const handleScroll = () => {
            const y = window.scrollY;
            const scrollHeight = document.body.scrollHeight;
            const windowHeight = window.innerHeight;

            if (scrollHeight <= windowHeight) {
                setScroll(-1);
                return;
            }

            const height = scrollHeight - windowHeight;

            setBarHeight((windowHeight / scrollHeight) * windowHeight);
            setScroll((scrollHeight - barHeight) * (y / height));
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll()
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        }
    });

    const onMouseMove = (e: React.MouseEvent) => {
        e.preventDefault()

        if (e.buttons !== 1) {
            return;
        }

        const speed = Math.max(2, ((document.body.scrollHeight / 3) / barHeight))
        window.scrollTo({ top: window.scrollY + (e.movementY * speed), behavior: 'auto'})
    }

    if (scroll === -1) {
        return <></>;
    }

    return (
        <div className={"z-10 absolute right-0 w-3 bg-scroll hover:bg-scroll-hover cursor-pointer drag:cursor-all-scroll rounded-lg select-none"}
             style={{height: barHeight + "px", top: scroll + "px"}}
             draggable={false}
             onMouseMove={onMouseMove}>
        </div>
    )
}