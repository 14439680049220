import React from "react";
import replace from "../util/replacer";

export default function Subtitle({children, replacements, id}: { children: string, replacements?: React.ReactElement[], id?: string }) {
    let elements = replace(children, replacements);

    return (
        <div className="px-3">
            <p id={id} className="text-light font-semibold text-3xl tracking-wide text-center">
                {elements}
            </p>
        </div>
    );
}
