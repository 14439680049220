import React from "react";

export default function Button({children, link, newTab = true, type = 'primary'}:
                                   {
                                       children: string, link: string, newTab?: boolean, type?: 'primary' | 'secondary'
                                   }) {

    let className = "w-fit px-normal-x py-normal-y rounded-normal font-semibold text-xl " +
        "border-2 select-none transition-colors duration-300 ease-in-out whitespace-nowrap";

    switch (type) {
        case 'primary':
            className += " text-light bg-accent border-accent hover:bg-transparent hover:border-light";
            break;
        case 'secondary':
            className += " text-light bg-transparent border-light hover:bg-accent hover:border-accent";
            break;
    }

    return (
        <div className="flex justify-center py-3">
            <a className={className} rel={"noreferrer"} target={newTab ? "_blank" : "_self"}
               href={link}>{children.toUpperCase()}</a>
        </div>
    );
}