import Title from "../components/title";
import Text from "../components/text";
import Button from "../components/button";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Carousel from "../components/carousel";
import {useInView} from "react-intersection-observer";

const MAX_ENTRIES = 20;

export default function History() {
    let {t} = useTranslation();
    const [data, setData] = useState<PastEvent[]>();
    const [current, setCurrent] = useState<string>("");
    const [entries, setEntries] = useState<LeaderboardEntry[]>();
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("");

    const {ref, inView} = useInView();

    const maxPage = () => {
        if (entries === undefined) {
            return 0;
        }

        if (search.length > 0) {
            return Math.ceil(currentSearchResults().length / MAX_ENTRIES);
        }

        return Math.ceil(entries?.length / MAX_ENTRIES);
    }

    const currentEntries = () => {
        if (entries === undefined) {
            return [];
        }

        if (search.length > 0) {
            return currentSearchResults()
                .slice(page * MAX_ENTRIES, (page + 1) * MAX_ENTRIES);
        }

        return entries.slice(page * MAX_ENTRIES, (page + 1) * MAX_ENTRIES);
    }

    const currentSearchResults = () => {
        if (entries === undefined) {
            return [];
        }

        return entries.filter(entry => entry.name.toLowerCase().includes(search.toLowerCase())
            || entry.uuid.toLowerCase() === search.toLowerCase());
    }

    useEffect(() => {
        if (maxPage() >= page) {
            return;
        }
        setPage(0);
    }, [search]);

    useEffect(() => {
        fetch(`/leaderboard/registered.json`)
            .then(response => response.json())
            .then(data => {
                setData(data);
                setCurrent(data[0].id);
            });
    }, []);

    useEffect(() => {
        if (!data) {
            return;
        }

        fetch(`/leaderboard/${current}.json`)
            .then(response => response.json())
            .then(setEntries)
            .catch(() => setEntries([]));
    }, [current]);

    if (!data) {
        return <></>;
    }

    const slideAnimation = (inView: boolean) => {
        return "transition-slide ease-in-out duration-1000 delay-100 "
            + (inView ? "opacity-100 translate-y-0" : "opacity-0 translate-y-[4rem]");
    };

    return (
        <div ref={ref} className="flex flex-col gap-7">
            <Title id="#history" title={t("history.title")}/>
            <div className={"flex flex-col bg-blue w-full max-w-[75%] mx-auto gap-4 py-3 " + slideAnimation(inView)}>
                <div className="flex flex-row justify-around text-light">
                    {data.length !== 1 ? <select
                        className="bg-transparent p-2 rounded-md w-fit min-w-[25%] select-none"
                        value={current}
                        onChange={e => setCurrent(e.target.value)}>
                        {data.map(event => <option key={event.id} value={event.id}>{event.name}</option>)}
                    </select> : <p className="p-2 rounded-md w-fit min-w-[25%] select-none">
                        {data.filter(event => event.id === current)[0].name}
                    </p>}
                    <div className="flex flex-row items-center gap-5">
                        <button onClick={() => setPage(page - 1)} disabled={page === 0}
                                className="text-light bg-transparent focus:bg-transparent hover:bg-transparent
                                disabled:text-secondary p-2 rounded-md w-fit select-none">
                            {"←"}</button>
                        <p className="text-light">{page + 1} / {maxPage() < (page + 1) ? (page + 1) : maxPage()}</p>
                        <button onClick={() => setPage(page + 1)} disabled={page >= maxPage() - 1}
                                className="text-light bg-transparent focus:bg-transparent hover:bg-transparent
                                disabled:text-secondary p-2 rounded-md w-fit select-none">
                            {"→"}</button>
                    </div>
                    <input type="text" placeholder={t("history.search")}
                           value={search}
                           onChange={e => setSearch(e.target.value)}
                           className="text-light bg-transparent focus:bg-transparent hover:bg-transparent p-2
                           rounded-md w-fit min-w-[25%] text-center"/>
                </div>
                {currentEntries().length === 0 &&
                    <div className="mx-auto"><Title title={t("history.no-results")}/></div>}
                {currentEntries().length !== 0 &&
                    <table className="w-full text-white">
                        <thead>
                        <tr className="max-w-[100%]">
                            <th className="w-[20%]">{t("history.table.placement")}</th>
                            <th className="w-[50%] text-left">{t("history.table.name")}</th>
                            <th className="w-[30%]">{t("history.table.distance")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentEntries().map(entry => {
                            let classNames = "";

                            switch (entry.placement) {
                                case 1:
                                    classNames += "text-yellow-400 font-bold";
                                    break;
                                case 2:
                                    classNames += "text-gray-300 font-bold";
                                    break;
                                case 3:
                                    classNames += "text-orange-700 font-bold";
                                    break;
                            }

                            return (
                                <tr key={entry.uuid} className={classNames}>
                                    <td className={"text-center"}>{entry.placement}</td>
                                    <td>
                                        <div>
                                            <img src={`https://crafatar.com/avatars/${entry.uuid}?size=20&overlay=true`}
                                                 alt={entry.name}
                                                 className="inline-block mr-2"/>
                                            {entry.name}
                                        </div>
                                    </td>
                                    <td className={"text-center"}>{Math.round(entry.distance)} Blöcke</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    );
}

interface LeaderboardEntry {
    name: string,
    uuid: string,
    distance: number,
    placement: number,
}

interface PastEvent {
    id: string,
    name: string,
    date: string,
}