import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enNs1 from './locales/en/ns1.json';
import deNs1 from './locales/de/ns1.json';

export const defaultNS = 'ns1';

i18next.use(initReactI18next).init({
    debug: true,
    lng: navigator.language,
    fallbackLng: 'en',
    defaultNS,
    resources: {
        en: {
            ns1: enNs1,
        },
        de: {
            ns1: deNs1,
        },
    },
});
