import React from "react";
import {useTranslation} from "react-i18next";

export default function Header() {
    let {t} = useTranslation();

    return (
        <div className="flex flex-col gap-7 border-b-2 border-blue-dark bg-blue py-4">
            <div className="flex justify-center gap-20">
                {/*    <img src="/header-logo.png" alt="logo" width={200}/>*/}
                {/*    <p className="text-light font-semibold text-2xl tracking-wide text-center my-auto">*/}
                {/*        {t("header.title")}*/}
                {/*    </p>*/}
                <a href="https://mcworld.run"><img className="select-none min-w-[80%]" src="/logo.svg"
                                                   alt="logo" width={300}/></a>
                <div className="flex justify-around gap-10 max-[1000px]:hidden">
                    <a href={"#info"}
                       className="text-light font-semibold text-2xl tracking-wide text-center my-auto uppercase select-none hover:text-accent transition-colors duration-200">
                        {t("header.info")}
                    </a>
                    <a href={"#participate"}
                       className="text-light font-semibold text-2xl tracking-wide text-center my-auto uppercase select-none hover:text-accent transition-colors duration-200">
                        {t("header.participate")}
                    </a>
                    <a href={"#players"}
                       className="text-light font-semibold text-2xl tracking-wide text-center my-auto uppercase select-none hover:text-accent transition-colors duration-200">
                        {t("header.players")}
                    </a>
                </div>
            </div>
        </div>
    );

    // return (
    //     <div className="flex flex-col gap-7 border-b-2 border-blue-dark bg-blue py-4">
    //         <div className="flex justify-center gap-4">
    //             <img src="/header-logo.png" alt="logo" width={200}/>
    //             <p className="text-light font-semibold text-2xl tracking-wide text-center my-auto">
    //                 {t("header.title")}
    //             </p>
    //         </div>
    //     </div>
    // );
}