import Title from "../components/title";
import React from "react";
import {useTranslation} from "react-i18next";
import Player, {PlayerProps} from "../components/player";
import {useInView} from "react-intersection-observer";

export default function Players() {
    let {t} = useTranslation();

    const {ref, inView} = useInView();

    const players: PlayerProps[] = [{
        name: "Veni",
        iconName: "./players/veni.png",
        twitchName: "Veni",
        youTubeName: "@VeniCraft",
        instagramName: "rafi_veni",
        tiktokName: "venitroll",
        xName: "Venitroll"
    }, {
        name: "Veyla",
        iconName: "./players/veyla.png",
        twitchName: "Veyla",
        youTubeName: "@veylarenee",
        instagramName: "veylarenee",
        tiktokName: "veylareneee",
        xName: "veylarenee"
    }]

    const toBeSorted: PlayerProps[] = [{
        name: "BastiGHG",
        iconName: "./players/bastighg.png",
        twitchName: "BastiGHG",
        youTubeName: "kompetenzGHG",
        instagramName: "babaesti",
        tiktokName: "bastighg.tiktok",
        xName: "kompetenzGHG"
    }, {
        name: "LetsHugo",
        iconName: "./players/letshugo.png",
        twitchName: "LetsHugoTV",
        youTubeName: "channel/UCGrEhW-D986zSjijnnhq5hg",
        instagramName: "letshugo",
        xName: "LetsHugoYT",
        tiktokName: "LetsHugoTV"
    }, {
        name: "Wichtiger",
        iconName: "./players/wichtiger.png",
        twitchName: "Wichtiger",
        youTubeName: "wichtiger",
        instagramName: "wichtigermike",
        tiktokName: "mikewichtiger",
        xName: "WichtigerYT"
    }, {
        name: "Fabo",
        iconName: "./players/fabo.png",
        twitchName: "fabo",
        youTubeName: "@TheFaboHD",
        instagramName: "dasfabo",
        tiktokName: "dasfabo",
        xName: "TheFaboHD"
    }, {
        name: "merleperle",
        iconName: "./players/merleperle.png",
        twitchName: "merleperle",
        youTubeName: "@merleperle",
        instagramName: "merlewlr",
        tiktokName: "merlewlr",
        xName: "Merlep3rle"
    }, {
        name: "NORMAN",
        iconName: "./players/norman.png",
        twitchName: "NORMAN",
        youTubeName: "@NQRMAN",
        instagramName: "nqrman",
        xName: "nqrman"
    }, {
        name: "Faister",
        iconName: "./players/faister.png",
        twitchName: "Faister",
        youTubeName: "@Faister",
        instagramName: "faistergram",
        tiktokName: "fasiterr",
        xName: "Faisterino"
    }, {
        name: "Dimeax",
        iconName: "./players/dimeax.png",
        twitchName: "Dimeax",
        youTubeName: "@dimeaxmc",
        instagramName: "d1meax",
        xName: "Dimeax_"
    }, {
        name: "Jens",
        iconName: "./players/jens.png",
        twitchName: "jens",
        youTubeName: "@jens.christensen",
        instagramName: "chris.jensensten",
        tiktokName: "jens.christensen"
    }, {
        name: "Fuxelbau",
        iconName: "./players/fuxelbau.png",
        twitchName: "Fuxelbau",
        youTubeName: "@Fuxel",
        instagramName: "fuxelbau",
        tiktokName: "fuxelclips",
        xName: "fuxelbau"
    }, {
        name: "SpaceMitX",
        iconName: "./players/spacemitx.png",
        twitchName: "SpaceMitX",
        youTubeName: "@SpaceMitX",
        instagramName: "SpaceMitX",
        tiktokName: "SpaceMitX",
        xName: "SpaceMitX"
    }, {
        name: "Lunarie",
        iconName: "./players/lunarie.png",
        twitchName: "Lunarie_TTV",
        youTubeName: "@lunarie_ttv",
        instagramName: "__Lunarie__",
        tiktokName: "lunarie_ttv",
        xName: "Lunarie_ttv"
    }, {
        name: "Tjan",
        iconName: "./players/tjan.png",
        twitchName: "Tjan",
        youTubeName: "@Tjan",
        instagramName: "tjantv",
        tiktokName: "tjantv",
        xName: "tjantv"
    }, {
        name: "Henke",
        iconName: "./players/henke.png",
        twitchName: "Henke",
        youTubeName: "@henkescorner",
        instagramName: "henkenbergen",
        tiktokName: "henkescorner",
        xName: "Henkenbergen"
    }, {
        name: "Rexyy",
        iconName: "./players/rexyy.png",
        twitchName: "rexyyboi",
        youTubeName: "@rexyyboi1",
        instagramName: "xrexyyboi",
        tiktokName: "xrexyyboi",
        xName: "Rexyyboi1"
    }, {
        name: "LadyNelsonx3",
        iconName: "./players/LadyNelsonx3.png",
        twitchName: "ladynelsonx3",
        instagramName: "ladynelsonx3"
    }, {
        name: "MindOfRick",
        iconName: "./players/MindOfRick.png",
        twitchName: "mindofr1ck",
        youTubeName: "@MindOfRick",
        xName: "MindOfR1ck"
    }, {
        name: "Konsti",
        iconName: "./players/Konsti.png",
        twitchName: "livekonsti",
        youTubeName: "@KonstiOnVideo",
        xName: "tweetkonsti"
    }, {
        name: "Schmisty",
        iconName: "./players/Schmisty.png",
        twitchName: "schmistyy",
        youTubeName: "@Schmistyy",
        instagramName: "schmistyy",
        tiktokName: "schmistyy",
        xName: "Schmistyy"
    }, {
        name: "Derliber",
        iconName: "./players/derliber.png",
        twitchName: "derliber",
        youTubeName: "@derliber",
        tiktokName: "derliber_",
        xName: "DerLiberLive"
    }, {
        name: "Spiro639",
        iconName: "./players/spiro639.png",
        twitchName: "spiro639",
        youTubeName: "@Spiro639",
        instagramName: "spiro639",
        tiktokName: "spiro639",
        xName: "spiro639"
    }, {
        name: "Logisch",
        iconName: "./players/LogischXD.png",
        twitchName: "zlogischsw",
        youTubeName: "@LogischXD",
        instagramName: "logischerxd",
        tiktokName: "logisch.official",
        xName: "LogischXD"
    }, {
        name: "Lucy Sebast",
        iconName: "./players/lucysebast.png",
        twitchName: "LucySebast",
        youTubeName: "Sebast",
        instagramName: "lucy.sebast",
        xName: "LucySebast"
    }, {
        name: "Urutox",
        iconName: "./players/urutox.png",
        twitchName: "urutox",
        youTubeName: "@Urutox",
        instagramName: "urutox",
        xName: "Urutox"
    }, {
        name: "katjatkx",
        iconName: "./players/katjatkx.png",
        twitchName: "katjatkx",
        youTubeName: "@katjatkx",
        instagramName: "katjatkx",
        tiktokName: "ekaterina.tkx",
        xName: "katjatkx"
    }, {
        name: "Toeddchen",
        iconName: "./players/Toeddchen.png",
        twitchName: "toeddchen",
        youTubeName: "@Toeddchen",
        instagramName: "toeddchen",
        tiktokName: "troeddchen",
        xName: "Toeddchen"
    }, {
        name: "joshiOhneC",
        iconName: "./players/joshiOhneC.png",
        twitchName: "joshiOhneC",
        youTubeName: "@JoshiohneeC"
    }, {
        name: "Traixless",
        iconName: "./players/Traixless.png",
        twitchName: "traixless",
        youTubeName: "@traixless7054",
        tiktokName: "traixless.twitch",
        xName: "Traixless"
    }, {
        name: "rhiinololo",
        iconName: "./players/rhiinololo.png",
        twitchName: "rhiinololo",
        youTubeName: "@rhiinololo",
        tiktokName: "rhiinololo"
    }, {
        name: "mareq",
        iconName: "./players/mareq.png",
        twitchName: "maaareq",
        youTubeName: "@Mareq",
        instagramName: "maaareq",
        tiktokName: "MaaareQ",
        xName: "maaaaareq"
    }, {
        name: "softyF",
        iconName: "./players/softyF.png",
        twitchName: "softyf",
        youTubeName: "@softyyF",
        instagramName: "wo.ist.softy",
        tiktokName: "softyf_twitch",
        xName: "softyF_"
    }, {
        name: "Clayten",
        iconName: "./players/Clayten.png",
        twitchName: "clayten",
        youTubeName: "@ClayMC",
        tiktokName: "clay_minecraft"
    }, {
        name: "marvinhrg",
        iconName: "./players/marvinhrg.png",
        twitchName: "marvinhrg",
        instagramName: "marvinhrg",
        tiktokName: "marvinhrg",
        xName: "marvinhrg"
    }, {
        name: "FrozenNoah",
        iconName: "./players/FrozenNoah.png",
        twitchName: "fr0zennoah",
        youTubeName: "@FrozenNoah",
        instagramName: "noah_dbm",
        tiktokName: "frozennoahat",
        xName: "frozennoahat"
    }, {
        name: "TrinoxDE",
        iconName: "./players/TrinoxDE.png",
        twitchName: "trinoxde",
        youTubeName: "@TrinoxDE",
        instagramName: "trinoxde",
        tiktokName: "trinoxde",
        xName: "TrinoxDE"
    }, {
        name: "LaudyTV",
        iconName: "./players/LaudyTV.png",
        twitchName: "LaudyTV",
        youTubeName: "@LaudyTV",
        instagramName: "laudy_tv",
        tiktokName: "laudy_tv",
        xName: "LaudyTV"
    }, {
        name: "Papaplatte",
        iconName: "./players/papaplatte.png",
        twitchName: "papaplatte",
        youTubeName: "@papaplatte",
        instagramName: "papaplatte",
        tiktokName: "papaplatte",
        xName: "papaplatte"
    }, {
        name: "xSiimao",
        iconName: "./players/xSiimao.png",
        twitchName: "xSiimao",
        youTubeName: "@xSiimao",
        tiktokName: "xsiimao",
        xName: "SiimaoX"
    }, {
        name: "KrustiBurger",
        iconName: "./players/krustiburger.png",
        twitchName: "krustiburger",
        youTubeName: "@KrustiBurger",
        instagramName: "krustiburgerr",
        tiktokName: "krustiburgerr",
        xName: "KrustiBurgerr"
    }, {
        name: "felikah",
        iconName: "./players/felikah.png",
        twitchName: "felikah",
        youTubeName: "@FelikahTTV",
        instagramName: "feliikah",
        tiktokName: "felikah.ttv",
        xName: "feliikah"
    }, {
        name: "Lisamariv",
        iconName: "./players/lisamariv.png",
        youTubeName: "@lisamariv",
        twitchName: "lisamariv",
        instagramName: "_lisamariv_"
    }, {
        name: "zRaager",
        iconName: "./players/zRaager.png",
        twitchName: "zraager",
        youTubeName: "@MrRaager",
        xName: "zraager"
    }, {
        name: "Huytastic",
        iconName: "./players/Huytastic.png",
        twitchName: "Huytastic",
        youTubeName: "@Huytastic",
        instagramName: "Huytastic",
        tiktokName: "Huytastic",
        xName: "Huytastic"
    }, {
        name: "TammTamm247",
        iconName: "./players/TammTamm247.png",
        twitchName: "tammtamm247",
        youTubeName: "@TammTamm247",
        instagramName: "tammtamm247",
        xName: "TammTamm247"
    }, {
        name: "Space",
        iconName: "./players/Space.png",
        twitchName: "space_warrior_19",
        instagramName: "space.warrior",
        xName: "spacewarrior09"
    }, {
        name: "amoaymen",
        iconName: "./players/amoaymen.png",
        twitchName: "amoaymen",
        youTubeName: "@amoaymen",
        instagramName: "amoaymenn",
        tiktokName: "amoaymen",
        xName: "amoaymn"
    }, {
        name: "SirExpert",
        iconName: "./players/SirExpert.png",
        twitchName: "sirexpert_",
        youTubeName: "@sirexpert",
        instagramName: "sirexpert",
        xName: "Sirexpert_"
    }, {
        name: "Fortgeschrittener",
        iconName: "./players/Fortgeschrittener.png",
        twitchName: "fortgeschrittener",
        youTubeName: "@Fortgeschrittener_YT",
        tiktokName: "fortgeschrittener",
        xName: "AMichel1337"
    }, {
        name: "xErogaki",
        iconName: "./players/xErogaki.png",
        twitchName: "xerogaki",
        youTubeName: "@xErogaki",
        instagramName: "xerogaki",
        tiktokName: "xerogaki"
    }/*{
        name: "",
        iconName: "./players/.png",
        twitchName: "",
        youTubeName: "",
        instagramName: "",
        tiktokName: "",
        xName: ""
    }*/];

    for (const prop of toBeSorted.sort((a, b) => a.name.localeCompare(b.name))) {
        players.push(prop);
    }

    return (
        <div ref={ref} className="flex flex-col gap-7">
            <Title id={"players"} title={t("players.title")}/>
            <div
                className="grid grid-cols-5 max-[1510px]:grid-cols-4 max-[1050px]:grid-cols-3 max-[750px]:grid-cols-2 max-[450px]:grid-cols-1 gap-10 items-center">
                {players.map((player, index) =>
                    <Player player={player} index={index} inView={inView} key={index}/>)}
            </div>
            {/**<div className="mx-auto">
             <Title title={t("players.to-be-announced")}/>
             </div>**/}
        </div>
    );
}