import './i18n';
import React from 'react';
import Info from "./sections/info";
import Participate from "./sections/participate";
import Players from "./sections/players";
import Footer from "./sections/footer";
import Header from "./sections/header";
import Scrollbar from "./components/scrollbar";
import Download from "./sections/download";
import History from "./sections/history";

function App() {
    return (
        <div className="min-h-[100vh] bg-gradient-to-b from-blue-light to-blue-dark flex flex-col gap-14 overflow-y-hidden">
            <Header/>
            <Scrollbar/>
            <div className="min-h-[100dvh]">
                <div className="max-w-[1500px] mx-auto flex flex-col gap-14">
                    <Info/>
                    <Participate/>
                    <Players/>
                    <Download/>
                    <History/>
                </div>
            </div>
            <Footer/>
        </div>
    )
        ;
}

export default App;
