import React, {useEffect, useState} from "react";
import Slider from "react-slick";

export default function Carousel() {
    const [minecraft, setMinecraft] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setMinecraft(m => !m);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const images: Image[] = [{}
        , {
            credits: "Marius Faulhaber // Red Bull Content Pool"
        }
        , {
            credits: "Mahmut Cinci for Wings for Life World Run // Red Bull Content Pool"
        }
        , {
            credits: "Filip Nagy for Wings for Life World Run // Red Bull Content Pool"
        }
    ]

    return (
        <Slider {...settings}>
            {images.map((image, index) => (
                <div className="text-center" key={index}>
                    <div className="relative h-[545px] w-full">
                        <img
                            className={"absolute inset-0 h-full object-cover transition-opacity duration-1000 ease-in-out m-auto max-h-[50vw]"
                                + (minecraft ? " opacity-0" : " opacity-100")}
                            src={`./slider/real-${index + 1}.png`}
                            height={"528px"}
                            alt={"Real " + index}/>
                        <img
                            className={"absolute inset-0 h-full object-cover transition-opacity duration-1000 ease-in-out m-auto max-h-[50vw]"
                                + (minecraft ? " opacity-100" : " opacity-0")}
                            src={`./slider/minecraft-${index + 1}.png`}
                            height={"528px"}
                            alt={"Minecraft " + index}/>
                    </div>
                    {image.credits && <p className="text-secondary">{image.credits}</p>}
                </div>
            ))}
        </Slider>
    );
}

interface Image {
    credits?: string;
}