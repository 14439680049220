import Title from "../components/title";
import Button from "../components/button";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Subtitle from "../components/subtitle";

export default function Footer() {
    let {t} = useTranslation();
    let [buttonDivs, setButtonDivs] = React.useState<React.ReactElement[]>([]);

    const links: JSX.Element[] = [
        (<a key={"r0"} href="https://www.mcworld.run/"
            className="font-bold text-accent hover:text-accent-hover transition-colors ease-in-out duration-300">Minecraft
            World Run</a>),
        (<a key={"r1"} href="https://www.twitch.tv/Veni"
            className="font-bold text-accent hover:text-accent-hover transition-colors ease-in-out duration-300">Veni</a>),
        (<a key={"r2"} href="https://www.twitch.tv/DerBanko"
            className="font-bold text-accent hover:text-accent-hover transition-colors ease-in-out duration-300">DerBanko</a>),
        (<a key={"r3"} href="https://www.twitter.com/Dweags"
            className="font-bold text-accent hover:text-accent-hover transition-colors ease-in-out duration-300">Dweags</a>)
    ]

    const buttonElements: JSX.Element[] = [
        <Button key={0} link="https://www.discord.gg/gTEPvAYFEA" newTab={true}
                type={'secondary'}>{t("footer.discord.button")}</Button>,
        <Button key={1} link="https://www.wingsforlifeworldrun.com/de/teams/XgMlJE" newTab={true}
                type={'secondary'}>{t("footer.team.button")}</Button>,
        <Button key={2} link="https://www.wingsforlife.com" newTab={true}
                type={'secondary'}>{t("footer.foundation.button")}</Button>,
        <Button key={3} link="https://www.wingsforlifeworldrun.com" newTab={true}
                type={'secondary'}>{t("footer.world-run.button")}</Button>,
        <Button key={4} link="https://www.twitter.com/MCWorldrun" newTab={true}
                type={'secondary'}>{t("footer.twitter.button")}</Button>,
    ];

    useEffect(() => {
        const handleResize = () => {
            let divs: React.ReactElement[] = [];
            let buttons: React.ReactElement[] = [];
            for (let i = 0; i < buttonElements.length; i++) {
                buttons.push(buttonElements[i]);
                if ((window.innerWidth < 600 && buttons.length === 1) ||
                    (window.innerWidth < 1280 && buttons.length === 2) ||
                    (window.innerWidth >= 1280 && buttons.length === 3)) {
                    divs.push(<div key={i}
                                   className="flex flex-row gap-6 justify-evenly max-w-[40%] mx-auto">{buttons}</div>);
                    buttons = [];
                }
            }
            if (buttons.length > 0) {
                divs.push(<div key={divs.length}
                               className="flex flex-row gap-6 justify-evenly max-w-[40%] mx-auto">{buttons}</div>);
            }
            setButtonDivs(divs);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            className="sticky bottom-0 flex flex-col gap-7 border-t-2 border-blue-light bg-blue-dark pt-4">
            <div className="mx-auto">
                <Title title={t("footer.title")}/>
            </div>
            <Subtitle replacements={links}>{t("footer.subtitle")}</Subtitle>
            <a href={"https://www.wingsforlifeworldrun.com"} className={"mx-auto w-fit max-h-[50vw] max-w-[400px]"}>
                <img className="w-full h-full" width={"50%"} src="./wingsforlifeworldrun.png"
                     alt="Wings for Life: World Run"/>
            </a>
            <div className="flex flex-col">
                {buttonDivs}
            </div>
            <p className="text-xs text-secondary mx-auto">{t("footer.disclaimer")}</p>
        </div>
    );
}