import Title from "../components/title";
import Text from "../components/text";
import Button from "../components/button";
import React from "react";
import {useTranslation} from "react-i18next";
import Carousel from "../components/carousel";

export default function Info() {
    let {t} = useTranslation();

    const textReplacements: React.ReactElement[] = [<span className="font-black">{t("info.text.you")}</span>,
        <a href={t("info.text.link.world-run")} className="underline hover:text-accent transition-colors duration-300">{t("info.text.hyperlink")}</a>,
        <a href={t("info.text.link.wings-for-life")} className="underline hover:text-accent transition-colors duration-300">{t("info.text.hyperlink")}</a>];

    const explanationReplacements: React.ReactElement[] = [<a href="https://www.wingsforlifeworldrun.com/de/teams/XgMlJE"
                                                              className="underline hover:text-accent transition-colors duration-300">{t("info.explanation.hyperlink")}</a>];

    return (
        <div className="flex flex-col gap-7">
            <Title id="#info" title={t("info.title")}/>
            <Text replacements={textReplacements}>{t("info.text")}</Text>
            <Button link="https://www.discord.gg/gTEPvAYFEA" newTab={true}>{t("info.discord.button")}</Button>
            <Carousel/>
            <Text replacements={explanationReplacements} className="pt-5">{t("info.explanation")}</Text>
        </div>
    );
}