import Title from "../components/title";
import Text from "../components/text";
import Button from "../components/button";
import React from "react";
import {useTranslation} from "react-i18next";
import Subtitle from "../components/subtitle";
import {useInView} from "react-intersection-observer";

const fixedDateMinecraft: Date = new Date(2024, 3, 28, 11, 0, 0, 0);
const fixedDateAppRun: Date = new Date(2024, 4, 5, 11, 0, 0, 0);

export default function Participate() {
    let {t} = useTranslation();

    const {ref, inView} = useInView();

    const tzDifferenceMinecraft: number = fixedDateMinecraft.getTimezoneOffset();
    const tzDifferenceAppRun: number = fixedDateAppRun.getTimezoneOffset();

    const dateMinecraft: Date = new Date(fixedDateMinecraft.getTime() - tzDifferenceMinecraft * 60 * 1000);
    const dateAppRun: Date = new Date(fixedDateAppRun.getTime() - tzDifferenceAppRun * 60 * 1000);

    const minecraftDate: string = getDateString(dateMinecraft);
    const appRunDate: string = getDateString(dateAppRun);
    const minecraftTime: string = getTimeString(dateMinecraft);
    const appRunTime: string = getTimeString(dateAppRun);

    const slideAnimation = (direction: string, inView: boolean) => {
        const transition = "transition-slide ease-in-out duration-500 delay-100 "
            + (inView ? "opacity-100 translate-x-0" : "opacity-0");

        if (inView) {
            return transition;
        }

        return transition + (direction === 'left' ? " translate-x-[-2rem]" : " translate-x-[2rem]");
    };

    return (
        <div ref={ref} className="flex flex-col gap-7">
            <Title id={"participate"} title={t("participate.title")}/>
            <div className="flex justify-around xl:flex-row flex-col max-xl:gap-7">
                <div className={"xl:max-w-[30%] flex flex-col gap-2 " + slideAnimation('left', inView)}>
                    <Subtitle>{t("participate.minecraft.title")}</Subtitle>
                    <Text
                        replacements={[<b key={"r1"}>{minecraftDate}</b>,
                            <b key={"r2"}>{minecraftTime}</b>]}>{t("participate.minecraft.text")}</Text>
                    <div className="flex max-[800px]:flex-col justify-center max-[800px]:gap-0 gap-3">
                        <Button link="#download" newTab={false}
                                type={'secondary'}>{t("participate.minecraft.button")}</Button>
                        <Button link={"https://derbanko.notion.site/MCWorldrun-General-Information-b83fb0e9d97e4bb9800a3df501888efb?pvs=4"}
                                type={'secondary'}>{t("participate.minecraft.information.button")}</Button>
                        {/**<Button link={getCalenderLink(t("participate.minecraft.calendar.title"), t("participate.minecraft.calendar.description"))}
                         type={'secondary'}>{t("participate.minecraft.calendar.button")}</Button>**/}
                    </div>
                </div>
                <div className={"xl:max-w-[30%] flex flex-col gap-2 " + slideAnimation('right', inView)}>
                    <Subtitle>{t("participate.app-run.title")}</Subtitle>
                    <Text
                        replacements={[<b key={"r1"}>{appRunDate}</b>,
                            <b key={"r2"}>{appRunTime}</b>]}>{t("participate.app-run.text")}</Text>
                    <Button link="https://www.wingsforlifeworldrun.com/de/teams/XgMlJE" newTab={true}
                            type={'secondary'}>{t("participate.app-run.button")}</Button>
                </div>
            </div>
        </div>
    );
}

function getCalenderLink(title: string, description: string): string {
    const location: string = "https://mcworld.run/";
    const start = fixedDateMinecraft.toISOString().replace(/-|:|\.\d+/g, '');
    const end = new Date(fixedDateMinecraft.getTime() + (4 * 60 * 60 * 1000)).toISOString().replace(/-|:|\.\d+/g, '');
    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(title)}&dates=${start}/${end}&location=${encodeURIComponent(location)}&details=${encodeURIComponent(description)}`;
}

function getDateString(date: Date): string {
    return date.toLocaleDateString(navigator.language, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })
}

function getTimeString(date: Date): string {
    return date.toLocaleTimeString(navigator.language, {hour: 'numeric', minute: 'numeric'})
}