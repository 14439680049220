import Title from "../components/title";
import React from "react";
import {useTranslation} from "react-i18next";
import Text from "../components/text";
import Button from "../components/button";
import Subtitle from "../components/subtitle";
import {useInView} from "react-intersection-observer";

export default function Download() {
    let {t} = useTranslation();

    const {ref, inView} = useInView();

    const requirements: React.ReactElement[] = [];

    for (let i = 0; i < 3; i++) {
        const requirement = t("download.requirements." + i).split("|");

        if (requirement.length === 1) {
            requirements.push(<Text key={i}>{requirement[0]}</Text>);
            continue;
        }

        requirements.push(<Text key={i}><a href={requirement[1]} className="underline hover:text-accent transition-colors duration-200 ease-in-out">{requirement[0]}</a></Text>);
    }

    const slideAnimation = (direction: string, inView: boolean) => {
        const transition = "transition-slide ease-in-out duration-500 delay-100 "
            + (inView ? "opacity-100 translate-x-0" : "opacity-0");

        if (inView) {
            return transition;
        }

        return transition + (direction === 'left' ? " translate-x-[-2rem]" : " translate-x-[2rem]");
    };

    return (
        <div ref={ref} className="flex flex-col gap-7">
            <Title title={t("download.title")}/>
            <div className="flex justify-around xl:flex-row flex-col max-xl:gap-7">
                <div className={"xl:max-w-[30%] flex flex-col gap-2 " + slideAnimation('left', inView)}>
                    <Subtitle id={"download"}>{t("download.subtitle")}</Subtitle>
                    <div>
                        <Text>{t("download.information")}</Text>
                        <Button link={"https://github.com/MCWorldrun/installer/releases/latest/download/installer.exe"} newTab={true}>{t("download.button.windows")}</Button>
                        <Button link={"https://github.com/MCWorldrun/installer/releases/latest/download/installer.jar"} newTab={true}>{t("download.button.other")}</Button>
                    </div>
                </div>
                <div className={"xl:max-w-[30%] flex flex-col gap-2 " + slideAnimation('right', inView)}>
                    <Subtitle>{t("download.requirements")}</Subtitle>
                    <ul className="flex flex-col gap-1">
                        {requirements}
                    </ul>
                </div>
            </div>
        </div>
    );
}