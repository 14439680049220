import React, {ReactElement} from "react";

export default function replace(children: string | React.ReactElement, replacements?: ReactElement[]): ReactElement | ReactElement[] {

    let string = children?.toString();

    if (string === undefined) {
        return <></>;
    }

    const elements: ReactElement[] = [];
    let text: string = "";

    if (replacements !== undefined) {
        let replacement = 0;
        for (let arg of string.split(" ")) {
            if (arg.includes("%s")) {
                const around: string[] = arg.split("%s");
                if (text !== "") {
                    text += around[0];
                    elements.push(<span key={elements.length}>{text}</span>);
                    text = "";
                }

                if (around.length >= 2) {
                    text += around[1];
                }

                elements.push(replacements[replacement++]);
                text += " ";
                continue;
            }

            text += arg + " ";
        }
    } else {
        elements.push(<span key={elements.length}>{string.trimEnd()}</span>)
    }

    if (text !== "") {
        elements.push(<span key={elements.length}>{text.trimEnd()}</span>);
    }

    return elements;
}