import React, {ReactElement} from "react";
import replace from "../util/replacer";

export default function Text({children, className, replacements}: {
    children: string | React.ReactElement,
    replacements?: ReactElement[],
    className?: string
}) {
    if (typeof children === "object") {
        return (
            <div className="px-3">
                <p className={"text-lighter text-lg text-center " + className}>{children}</p>
            </div>
        );
    }

    let elements = replace(children, replacements);

    return (
        <div className="px-3">
            <p className={"text-lighter text-lg text-center " + className}>{elements}</p>
        </div>
    );
}
